<template>
  <template v-if="alreadySignedOut">
    <h2>Logga ut</h2>
    <p>Du är redan utloggad.</p>
  </template>

  <template v-else>
    <h2>Logga ut</h2>
    <p>Du har nu blivit utloggad. Tack för ditt besök!</p>
  </template>
</template>

<script>
import { useStore } from '@/store'

export default {
  name: 'SignOut',

  data: () => ({
    alreadySignedOut: false,
    store: useStore(),
  }),

  created() {
    this.alreadySignedOut = !this.store.user

    if (!this.alreadySignedOut) {
      this.store.setUser(false)
      document.cookie = 'jwt=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  },
}
</script>
