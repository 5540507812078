import { defineStore } from 'pinia'

export const useStore = defineStore('store', {
  state: () => {
    return {
      user: null,
      categories: [
        {
          id: 'BAGS',
          name: 'Väskor',
        },
        {
          id: 'GLOVES',
          name: 'Handskar',
        },
        {
          id: 'JACKETS',
          name: 'Jackor',
        },
        {
          id: 'TENTS',
          name: 'Tält',
        },
      ],
      brands: [
        {
          id: 'CANADA-GOOSE',
          name: 'Canada Goose',
        },
        {
          id: 'F',
          name: 'Fjällräven',
        },
        {
          id: 'H',
          name: 'Haglöfs',
        },
        {
          id: 'P',
          name: 'Patagonia',
        },
        {
          id: 'TNF',
          name: 'The North Face',
        },
        {
          id: 'HOME_MADE',
          name: 'Hem- och egengjort',
        },
        {
          id: 'OTHER',
          name: 'Annat varumärke',
        },
      ],
    }
  },

  actions: {
    setUser(user) {
      this.user = user
    },
  },
})
