<template>
  <pre>{{ store.user }}</pre>
</template>

<script>
import { useStore } from '@/store'

export default {
  name: 'Home',

  data: () => ({
    store: useStore(),
  })
}
</script>

<style scoped>
.columns {
  display: flex;
}

.columns > .column {
  flex-basis: 0;
  height: 80vh;
}

.front img {
  width: 100%;
}
</style>
