<template>
  <template v-if="store.user">
    <h2>Skapa konto</h2>
    <p>Du är redan inloggad.</p>
  </template>

  <v-row v-else>
    <v-col cols="4">
      <h2>Skapa konto</h2>
      <p>För att skapa ett konto, ange ditt namn, din e-postadress och ett lösenord.</p>

      <p>Har du redan ett konto? <router-link to="/logga-in">Logga in »</router-link></p>
    </v-col>

    <v-col cols="4">
      <v-form @submit.prevent="signUp">
        <v-text-field
          label="För- och efternamn"
          type="input"
          v-model="name"
          :error-messages="errors.name ? [{'INVALID': 'Ange ditt för- och efternamn.'}[errors.name]] : []"
        />

        <v-text-field
          label="E-postadress"
          type="input"
          v-model="emailAddress"
          :error-messages="errors.emailAddress ? [{'INVALID': 'E-postadressen är ogiltig.', 'EXISTS': 'E-postadressen används redan.'}[errors.emailAddress]] : []"
        />

        <v-text-field
          label="Lösenord"
          type="password"
          v-model="password"
          :error-messages="errors.password ? [{'TOO_SHORT': 'Lösenordet måste vara minst sex tecken långt.'}[errors.password]] : []"
        />

        <v-btn
          type="submit"
          class="mt-2"
          color="primary"
          :disabled="!emailAddress || !password || isLoading"
          block
        >
          Skapa konto
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

import { useStore } from '@/store'

export default {
  name: 'SignUp',

  data: () => ({
    name: '',
    emailAddress: '',
    password: '',
    isLoading: false,
    errors: {},
    store: useStore(),
  }),

  methods: {
    async signUp() {
      this.isLoading = true
      this.errors = {}

      try {
        const user = (await axios.post('/sign-up', {
          name: this.name,
          emailAddress: this.emailAddress,
          password: this.password,
        })).data

        this.store.setUser(user)

        this.$router.push({
          name: 'home',
        })
      } catch (error) {
        console.log(error.response)
        this.errors = error.response.data
      }

      this.isLoading = false
    }
  },
}
</script>
