<template>
  <template v-if="store.user">
    <h2>Logga in</h2>
    <p>Du är redan inloggad.</p>
  </template>

  <v-row v-else>
    <v-col cols="4">
      <v-form @submit.prevent="signIn">
        <v-text-field
          label="E-postadress"
          type="input"
          v-model="emailAddress"
        />

        <v-text-field
          label="Lösenord"
          type="password"
          v-model="password"
        />

        <v-btn
          type="submit"
          class="mt-2"
          color="primary"
          :disabled="!emailAddress || !password || isLoading"
          block
        >
          Logga in
        </v-btn>
      </v-form>
    </v-col>

    <v-col cols="8">
      <h2>Logga in</h2>
      <p>Fyll i din e-postadress och ditt lösenord för att logga in.</p>

      <p>Har du inget konto? <router-link to="/skapa-konto">Registrera dig »</router-link></p>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

import { useStore } from '@/store'

export default {
  name: 'SignIn',

  data: () => ({
    emailAddress: '',
    password: '',
    isLoading: false,
    isError: false,
    store: useStore(),
  }),

  methods: {

    async signIn() {
      this.isLoading = true
      this.isError = false

      try {
        const user = (await axios.post('/sign-in', {
          emailAddress: this.emailAddress,
          password: this.password,
        })).data

        this.store.setUser(user)

        this.$router.push({
          name: 'home',
        })
      } catch (error) {
        this.isError = true
      }

      this.isLoading = false
    }
  },
}
</script>
