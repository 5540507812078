<template>
  <v-app v-if="store.user !== null">
    <div class="wrapper">
      <div class="header">
        <div class="logotype"></div>
        <div class="nav">
          <router-link
            to="/"
            :class="{ active: $route.name === 'home' }"
          >
            Hem
          </router-link>
          <router-link to="/">Kläder</router-link>
          <router-link to="/">Väskor</router-link>
          <router-link to="/">Skor</router-link>
          <div style="flex-grow: 1;"></div>

          <template v-if="store.user">
            <router-link
              to="/logga-ut"
              :class="{ active: $route.name === 'sign-out' }"
            >
              Logga ut
            </router-link>
          </template>

          <template v-else>
            <router-link
              to="/logga-in"
              :class="{ active: $route.name === 'sign-in' }"
            >
              Logga in
            </router-link>

            <router-link
              to="/skapa-konto"
              :class="{ active: $route.name === 'sign-up' }"
            >
              Skapa konto
            </router-link>
          </template>
        </div>

        <router-link
          class="cart"
          to="/cart"
        >
          <v-icon icon="mdi-cart" />
          2
        </router-link>

        <router-link
          to="/ny-annons"
          class="newProduct"
        >
          Ny annons
        </router-link>
      </div>

      <div class="content">
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios'

import { useStore } from '@/store'

export default {
  name: 'App',

  data: () => ({
    store: useStore(),
  }),

  async created() {
    try {
      const user = (await axios.get('/me')).data

      this.store.setUser(user)
    } catch {
      this.store.setUser(false)
    }
  }
}
</script>

<style scoped>
.wrapper {
  margin: 0 auto;
  width: max(900px, 80vw);
}

.header {
  display: flex;
  align-items: center;
  font-family: 'Fira Sans';
}

.header .logotype {
  background-image: url('assets/logotype.png');
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  background-size: cover;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 50px;
}

.header .nav {
  flex-grow: 1;
  display: flex;
  margin: 0 50px;
  font-weight: 400;
}

.header a,
.header a:hover,
.header a:active,
.header a:visited {
  display: block;
  text-decoration: none;
  color: #333333;
}

.header .nav a {
  font-size: 1.3rem;
  padding: 20px;
  border-bottom: 2px solid transparent;
  margin: 0 2px;
}

.header .nav a:hover,
.header .nav a.active {
  border-bottom: 2px solid #2b6964;
}

.header .cart {
  font-size: 1.4rem;
  margin-right: 2em;
}

.header .newProduct,
.header .newProduct:hover,
.header .newProduct:active,
.header .newProduct:visited {
  flex-grow: 0;
  margin-right: 50px;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 1.3rem;
  color: #f2fffe;
  background-color: #2b6964;
}

.header .newProduct:hover {
  color: #ffffff;
}
</style>
