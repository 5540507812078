<template>
  <h1>Ny annons</h1>
  <p>Beskriv det du vill sälja, sätt ett pris och ladda upp ett fotografi.</p>

  <v-stepper
    v-model="currentStep"
    :next-text="nextStepText"
    prev-text="Tillbaka"
  >
    <v-stepper-header>
      <v-stepper-item
        v-for="(step, index) in steps"
        :key="step"
        :complete="isComplete(index)"
        :title="step"
        :rules="currentStep > index ? [() => isComplete(index)] : []"
        editable
      />
    </v-stepper-header>

    <v-stepper-window v-model="currentStep">
      <v-stepper-window-item :value="0">
        <v-layout>
          <v-row>
            <v-col cols="6">
              <v-select
                label="Kategori"
                item-title="name"
                item-value="id"
                v-model="listing.category"
                :items="store.categories"
              />
            </v-col>

            <v-col cols="6">
              <v-select
                label="Varumärke"
                item-title="name"
                item-value="id"
                v-model="listing.brand"
                :items="store.brands"
              />
            </v-col>
          </v-row>
        </v-layout>

        <v-text-field
          v-model="listing.title"
          label="Titel"
        />

        <v-textarea
          label="Beskrivning"
          hint="Beskriv varan du vill sälja, och vilket skicka den är i"
          v-model="listing.description"
        />
      </v-stepper-window-item>

      <v-stepper-window-item :value="1">
        <v-container>
          <v-row justify="center">
            <v-col cols="6">
              <p>Vad är ett bra pris? Om du är osäker, se efter vad nypriset är, och fundera sedan på vad du själv hade kunnat tänka dig att betala.</p>

              <v-text-field
                class="centered-input"
                v-model="listing.price"
                :rules="[rules.price]"
                suffix="kronor"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-window-item>

      <v-stepper-window-item :value="2">
        <v-container>
          <v-row justify="center">
            <v-col cols="6">
              <p>Välj minst ett fotografi av din vara.</p>

              <v-file-input
                accept="image/*"
                label="Välj fotografi"
                v-model="listing.picture"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-window-item>

      <v-stepper-window-item :value="3">
        <v-alert
          v-if="[0, 1, 2].map(i => isComplete(i)).filter(x => !x).length > 0"
          type="error"
          title="Annonsen är ofullständig"
          text="Det finns delar i annonsen som inte är ifyllda ordentligt. För att publicera din annons, gå tillbaka och fyll i det som saknas."
        />
      </v-stepper-window-item>
    </v-stepper-window>

    <v-stepper-actions
      @click:next="currentStep++"
      @click:prev="currentStep--"
    >
      <template #next v-if="currentStep === steps.length - 1" />
    </v-stepper-actions>
  </v-stepper>
</template>

<script>
import { useStore } from '@/store'

export default {
  name: 'NewProduct',

  data: () => ({
    currentStep: 0,
    steps: [
      'Beskrivning',
      'Pris',
      'Fotografier',
      'Publicera',
    ],
    store: useStore(),
    listing: {
      title: '',
      description: '',
      category: null,
      brand: null,
      price: null,
      picture: null,
    },
    rules: {
      price: value => /^[0-9]+$/.test(value) || 'Ange ett pris i kronor.',
    },
  }),

  computed: {
    nextStepText: function() {
      if (this.currentStep === 2) {
        return 'Publicera annons'
      } else {
        return 'Nästa steg'
      }
    },
  },

  methods: {
    isComplete(stepIndex) {
      switch (stepIndex) {
        case 0:
          return !!this.listing.title && !!this.listing.description && !!this.listing.category && !!this.listing.brand

        case 1:
          return this.listing.price !== '' && this.listing.price !== null
        
        case 2:
          return this.listing.picture !== null
        
        case 3:
          return false

        default:
          return true
      }
    }
  },
}
</script>

<style scoped>
/deep/ .centered-input input {
  text-align: center
}
</style>
