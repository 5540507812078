import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'

import CartView from './views/Cart.vue'
import HomeView from './views/Home.vue'
import ProductsView from './views/Products.vue'
import NewProductView from './views/NewProduct.vue'
import SignInView from './views/SignIn.vue'
import SignUpView from './views/SignUp.vue'
import SignOutView from './views/SignOut.vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import './assets/css/main.css'

axios.defaults.baseURL = '/api/'

loadFonts()

library.add(faCartShopping)

const routes = [
  {
    path: '/',
    component: HomeView,
    name: 'home',
  },
  {
    path: '/hitta/kläder',
    component: ProductsView,
    name: 'products-clothes',
  },
  {
    path: '/hitta/väskor',
    component: ProductsView,
    name: 'products-bags',
  },
  {
    path: '/hitta/attiraljer',
    component: ProductsView,
    name: 'products-miscellaneous',
  },
  {
    path: '/kundvagn',
    component: CartView,
    name: 'cart',
  },
  {
    path: '/ny-annons',
    component: NewProductView,
    name: 'new-product',
  },
  {
    path: '/logga-in',
    component: SignInView,
    name: 'sign-in',
  },
  {
    path: '/skapa-konto',
    component: SignUpView,
    name: 'sign-up',
  },
  {
    path: '/logga-ut',
    component: SignOutView,
    name: 'sign-out',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const pinia = createPinia()

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(vuetify)
app.use(router)
app.use(pinia)

app.mount('#app')
